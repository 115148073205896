import styled from "styled-components";

export const StyledContent = styled.div`
  padding: var(--lib-default-spacing);
  width: min-content;

  display: grid;
  grid-auto-flow: row;

  // Pass down
  font-size: var(--lib-text-size-0);
  color: var(--lib-secondary-gray);

  .card-header {
    width: min-content;
  }
`;

export const StyledCardTitle = styled.h3`
  color: var(--lib-primary-gray);
  font-size: var(--lib-text-size-1);
  font-weight: 700; //prevent extra tracking on Safari
  text-decoration: none;

  width: min-content;
  white-space: nowrap;
`;
