import React, { useEffect, useState } from "react";

import { useTranslation, I18nextProvider } from "react-i18next";
import instance from "../../utils/i18n";

import EsriSearch from "@arcgis/core/widgets/Search";
import { StyledSearchWrapper, StyledSearchWrapperText } from "./Search-styled";
import { SearchProps } from "./Search.types";
import { _getFeatureLayerSource, _getLocatorSource, _handleSearchComplete } from "./SearchHelper";

import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-icon";
import { CalciteButton, CalciteIcon } from "@esri/calcite-components-react";

const Search = ({
  mapView,
  sourceInfos,
  searchTerm,
  isLanding,
  onSearchStart,
  onSearchClear,
  onSearchComplete,
  onSearchBlocked,
  onSearchError,
}: SearchProps) => {
  const [search, setSearch] = useState<EsriSearch | undefined>();
  const [isMyLocLoading, setIsMyLocLoading] = useState<boolean>(false);

  const { t, i18n } = useTranslation("components", { i18n: instance });

  useEffect(() => {
    const createSearch = () => {
      const sources = sourceInfos?.map((info) => {
        switch (info.type) {
          case "layer":
            return _getFeatureLayerSource(info, t);
          case "locator":
            return _getLocatorSource(info, t);
          default:
            throw new Error(t("search.sourceInfoError"));
        }
      });
      const newSearch = new EsriSearch({
        view: mapView,
        container: "searchDiv",
        sources: sources,
        searchTerm,
        includeDefaultSources: false,
      });

      newSearch.on("search-start", () => {
        onSearchStart?.();
      });

      newSearch.on("search-complete", (e) => {
        _handleSearchComplete(e, onSearchComplete, onSearchError);
      });

      newSearch.on("search-clear", () => {
        onSearchClear?.();
      });

      setSearch(newSearch);
    };

    if (!search && sourceInfos) {
      createSearch();
    }
    // including search & searchTerm causes infinate loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapView, onSearchClear, onSearchStart, sourceInfos]);

  useEffect(() => {
    if (search) {
      search.searchTerm = searchTerm;
    }
  }, [search, searchTerm]);

  useEffect(() => {
    // shift focus to search widget after it has been initialized
    search?.when(() => {
      search?.focus();
    });
  }, [search]);

  const onLocate = () => {
    if (navigator.geolocation) {
      setIsMyLocLoading(true);
      onSearchStart?.();
      navigator.geolocation.getCurrentPosition(
        (event) => {
          setIsMyLocLoading(false);
          onSearchComplete?.({ searchTerm: t("search.nearMe"), position: event });
        },
        (error) => {
          console.warn(t("search.geolocateError", { error: error }));
          setIsMyLocLoading(false);
          onSearchBlocked?.();
        }
      );
    }
  };

  return (
    <I18nextProvider i18n={i18n}>
      <StyledSearchWrapper>
        <div id="searchDiv" />
        {isLanding ? <StyledSearchWrapperText>{t("search.or")}</StyledSearchWrapperText> : null}
        <CalciteButton
          id="myloc-btn"
          appearance="outline-fill"
          kind="neutral"
          label={t("search.viewNearbyLocs")}
          round={isLanding}
          scale="m"
          onClick={onLocate}
          data-testid="btnLocateme"
          loading={isMyLocLoading || undefined}
        >
          <CalciteIcon icon="gps-on" scale="s"></CalciteIcon>
          {isLanding ? t("search.nearby") : ""}
        </CalciteButton>
      </StyledSearchWrapper>
    </I18nextProvider>
  );
};

export default React.memo(Search);
