import React from "react";
import { StyledCardTitle, StyledContent } from "./ZoneCard-styled";
import { ZoneCardProps } from "./ZoneCard.types";

import { I18nextProvider, useTranslation } from "react-i18next";
import instance from "../../utils/i18n";

import "@esri/calcite-components/dist/components/calcite-button";

const ZoneCard = ({ zoneNumber }: ZoneCardProps) => {
  const { t, i18n } = useTranslation("components", { i18n: instance });

  return (
    <I18nextProvider i18n={i18n}>
      <StyledContent>
        <div className="card-header">
          <StyledCardTitle className="card-title">
            {t("zoneCard.zone", { zoneNum: zoneNumber })}
          </StyledCardTitle>
        </div>
      </StyledContent>
    </I18nextProvider>
  );
};

export default ZoneCard;
