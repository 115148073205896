import styled from "styled-components";
import { StyledFilterProps } from "./Filter.types";
import { Breakpoints } from "../AppTheme";

/* 
Typography 
*/

export const StyledFilterTitle = styled.h2`
  font-size: var(--lib-text-size-2);
  color: var(--lib-primary-gray);
  text-decoration: none;
`;

export const StyledSectionHeading = styled.h3`
  font-size: var(--lib-text-size-1);
  color: var(--lib-primary-gray);
  text-transform: uppercase;
  padding-bottom: var(--lib-default-spacing);
`;

/* 
Sections to organize narrow/fullscreen filter, styled in parent 
*/

export const StyledFilterSection = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;

  // Pass down
  font-size: var(--lib-text-size-0);
  color: var(--lib-secondary-gray);
`;

//see StyledFilterNarrow
export const StyledFilterBySection = styled(StyledFilterSection)``;

/* 
Filter parent containers 
*/

// Template component, extended by row and column ("fullscreen") variants
export const StyledFilter = styled.div<StyledFilterProps>`
  width: 100%;
  box-sizing: border-box;

  background-color: var(--lib-foreground);
`;

export const StyledFilterRowLayout = styled(StyledFilter)`
  // Align perfectly with List
  padding: var(--lib-default-spacing);
  padding-left: calc(var(--lib-default-spacing) + 4px);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /*container for all but clear all button - necessary to justify last element to the right with space-between */
  #filter-left-side {
    height: min-content;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    align-items: end;
  }

  //hide in row layout
  .filter-condensed,
  ${StyledSectionHeading}, #filter-show-all-container {
    display: none;
  }

  //offset default padding on calcite-label
  .filter-padding-offset {
    margin-bottom: -12px;
  }

  //necessary as elements with display:none were messing up gap
  .filter-spacing-offset {
    padding-right: var(--lib-default-spacing);
    padding-top: 0.5rem;
    padding-left: 0.5rem;
  }

  /* visually hide alert that screenreader is to announce */
  #filters-cleared-alert {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  @media ${Breakpoints.mobile} {
    //prevent "clear all" from wrapping onto two lines as this disturbs the hidden flex row
    .no-wrap {
      white-space: nowrap;
    }

    //hide labels
    .filter-btn-label {
      display: none;
    }
    /* hide filters one by one as the screen grows smaller, by pushing them into a hidden flex row */
    #filter-left-side {
      align-items: center;
      height: 2rem;
      flex-wrap: wrap;
      overflow-y: hidden;
      //push second row down
      gap: 1rem 0px;
    }

    .filter-spacing-offset {
      padding-top: 0;
    }

    //default
    #filter-show-all-container {
      display: block;
    }
  }
`;

export const StyledFilterColumnLayout = styled(StyledFilter)`
  //visual contrast between last section and List
  --shadow: var(--lib-shadow) 0px 2px 4px;
  box-shadow: var(--shadow);

  //first and last rows
  ${StyledFilterSection} {
    padding: var(--lib-default-spacing);
    box-sizing: border-box;
    border-bottom: 1px solid var(--lib-background);

    justify-content: space-between;
  }

  ${StyledFilterBySection} {
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
  }

  #filter-show-all-container,
  .filter-btn-label {
    display: none;
  }

  @media ${Breakpoints.mobile} {
    z-index: 2; // display over show list button

    #filter-left-side {
      height: calc(70vh);
      overflow-y: scroll;
    }
  }
`;

export const AllFiltersContainer = styled.div``;
export const StyledBadge = styled("div")<{ hidden: boolean; top: number }>`
  display: none;
  @media ${Breakpoints.mobile} {
    display: ${(props) => (props.hidden ? "none" : "flex")};
    margin-top: -40px;
    margin-left: 30px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    position: absolute;
    background: var(--lib-primary-blue);
    color: white;
    border: 1px solid white;
    align-items: center;
    justify-content: center;
  }
`;
