import styled from "styled-components";

export const StyledWarningTitle = styled.span`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: var(--lib-text-size-2);
`;

export const StyledMessage = styled.div`
  //box-model
  width: 100%;
  height: min-content;
  box-sizing: border-box;
  padding: var(--lib-default-spacing);

  //layout
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto; // info-content x-btn
  gap: var(--lib-default-spacing);

  color: var(--message-color); //passed in through inline styles, either gray or green
  background-color: var(--lib-foreground);

  * {
    color: inherit; //--message-color or #fff for warning
  }

  ${StyledWarningTitle} {
    display: none; // show in warning
  }

  #info-content {
    width: 100%;

    // using flex, not grid to control for layouts with different #s of children
    display: flex;
    align-items: center;

    // icon
    & > :first-child:not(p) {
      margin-inline-end: var(--lib-default-spacing);
    }
  }

  #question-icon,
  #square-icon {
    height: calc(var(--lib-default-spacing) * 2);
    aspect-ratio: 1;
  }
`;

export const StyledSpan = styled.span`
  font-weight: bold;
`;

export const StyledWarning = styled(StyledMessage)`
  background-color: var(--lib-primary-red);
  color: white;

  grid-auto-flow: row;
  grid-template-columns: initial;
  justify-content: center;

  ${StyledWarningTitle} {
    display: block; // show in warning
  }

  path {
    fill: white;
  }
`;
