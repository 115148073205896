import React from "react";
import { StyledFieldSection, StyledSectionHeading, StyledServiceNote } from "../Details-styled";
import { SingleFieldSectionDetails } from "../Details.types";

const SingleFieldSection = ({
  loc,
  fieldName,
  sectionTitle,
  staticContent,
}: SingleFieldSectionDetails) => {
  let text = loc.attributes[fieldName] || "";
  if (staticContent) {
    if (text) {
      text += "\n\n";
    }
    text += staticContent;
  }
  return (
    <StyledFieldSection key={`singleField_${fieldName}`}>
      {sectionTitle && <StyledSectionHeading>{sectionTitle}</StyledSectionHeading>}
      <StyledServiceNote>{text}</StyledServiceNote>
    </StyledFieldSection>
  );
};

export default SingleFieldSection;
