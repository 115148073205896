import styled from "styled-components";
import { Breakpoints } from "../AppTheme";

/* Typography */

//identical to StyledCardTitle
export const StyledLocationTitle = styled.h2`
  font-size: var(--lib-text-size-1);
  color: var(--lib-primary-gray);
  font-weight: bold;
  text-decoration: none;
`;

//uppercase headings
export const StyledSectionHeading = styled.h3`
  font-size: var(--lib-text-size-0);
  color: var(--lib-primary-gray);
  text-transform: uppercase;
`;

export const StyledServiceNote = styled.p`
  color: var(--lib-primary-green);
  white-space: pre-wrap;
`;

/* Containers */

// Modal
export const StyledDetailsWrapper = styled.div`
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 2;
  background-color: var(--lib-shadow-dark);
`;

// Organizes sections
export const StyledDetailsGrid = styled.div`
  display: flex;
  flex-direction: column;

  @media ${Breakpoints.mobile} {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
`;

// Base class for rows within StyledDetailsGrid
const StyledDetailsSection = styled.div`
  padding: var(--lib-default-spacing);
  box-sizing: border-box;
  border-bottom: 1px solid var(--lib-background);

  display: grid;

  background-color: var(--lib-foreground);

  // Pass down
  font-size: var(--lib-text-size-0);
  color: var(--lib-secondary-gray);
`;

export const StyledCardSection = styled(StyledDetailsSection)`
  height: min-content;
  width: 100%;
  grid-template-columns: 1fr min-content; //content x-button
  grid-gap: var(--lib-default-spacing);
  border-bottom: 0px;
`;
export const StyledHoursSection = styled(StyledDetailsSection)`
  height: auto;
  grid-auto-flow: row;
  grid-gap: var(--lib-default-spacing);
  overflow-y: auto;

  ${StyledServiceNote} {
    :not(#hoursDisclaimer) {
      padding-inline-start: 0.5rem;
      border-inline-start: 2px solid var(--lib-primary-green);
      margin-inline-start: var(--lib-default-spacing);
    }
  }

  > * {
    width: fit-content;
    display: grid;
    grid-auto-flow: row;
    grid-gap: var(--lib-default-spacing);

    justify-items: start;
  }
`;

export const StyledMapSection = styled(StyledDetailsSection)`
  flex-grow: 1;
  grid-template-rows: min-content 1fr; // map-section-header map

  #map-section-header {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr auto; //heading zoom-to-btn
    align-items: center;
  }

  // Hide title, map on desktop
  ${StyledSectionHeading}, > :nth-child(2) {
    display: none;
  }

  // Center on desktop
  #zoom-to-btn {
    margin-left: auto;
    margin-right: auto;
  }

  @media ${Breakpoints.mobile} {
    height: auto;
    //Add gap here as it won't respect "display: none" in desktop layout
    grid-gap: var(--lib-default-spacing);

    // Green headings and map
    ${StyledSectionHeading}, > :nth-child(2) {
      display: flex;
    }
    // Map
    > :nth-child(2) {
      min-height: 5rem;
      height: 100%;
    }
  }
`;

export const StyledMinMaxSection = styled(StyledDetailsSection)`
  height: min-content;

  ${StyledSectionHeading} {
    padding-bottom: var(--lib-default-spacing);
  }

  .minmax-row {
    padding-bottom: calc(var(--lib-default-spacing) / 2);

    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 18rem auto;
    gap: calc(var(--lib-default-spacing) * 2);
  }
`;

export const StyledFieldSection = styled(StyledDetailsSection)`
  height: min-content;
  grid-auto-flow: row;
  grid-gap: var(--lib-default-spacing);

  // only bold if no section title
  :first-child(${StyledServiceNote}) {
    font-weight: bold;
  }
`;

/* Services components */

//wraps ServiceOption
export const StyledServiceDropdown = styled.button`
  border: none;
  background-color: var(--lib-foreground);
  cursor: pointer;

  & span {
    padding-inline-end: 10px;
  }
`;

export const StyledTable = styled.table`
  margin-inline-start: var(--lib-default-spacing);
  border-inline-start: 2px solid var(--lib-primary-green);

  table-layout: fixed;
  border-collapse: separate;

  overflow-y: auto;
`;

export const StyledHoursRow = styled.tr`
  vertical-align: baseline;

  //day name
  th {
    padding: 0.25rem 0.5rem;
    color: var(--lib-secondary-gray);
    text-align: start;
  }
  //up to 3 paragraphs in a td
  & td p {
    padding-inline-start: var(--lib-default-spacing);
    color: var(--lib-primary-gray);
  }
`;

//entire row is bold if weekday matches today's date
export const StyledHighlightedHoursRow = styled(StyledHoursRow)`
  * {
    font-weight: bold;
  }
`;
