// Framework and third-party non-ui
import "@esri/calcite-components/dist/components/calcite-combobox";
import "@esri/calcite-components/dist/components/calcite-combobox-item";
import "@esri/calcite-components/dist/components/calcite-label";
import { CalciteCombobox, CalciteComboboxItem, CalciteLabel } from "@esri/calcite-components-react";
import React from "react";
import instance from "../../utils/i18n";
import * as intl from "@arcgis/core/intl.js";

import { useTranslation, I18nextProvider } from "react-i18next";
import { TranslationSelectI18nProps } from "./TranslationSelectI18n.types";

const TranslationSelectI18n = ({ onChange }: TranslationSelectI18nProps) => {
  const { t, i18n } = useTranslation("components", { i18n: instance });
  // useTranslate issue with key `app.filter.categoryFilters.appId.arrayIndex.key` getting object array
  const languageLabels = t(`languageSelect.languages`, { returnObjects: true });

  console.log(i18n.language);

  return (
    <I18nextProvider i18n={i18n}>
      <CalciteLabel className="calcite-label-padding-offset" layout="inline">
        {t("languageSelect.label")}
        <CalciteCombobox
          className="lib-neutral-button"
          id="translate-combobox"
          label="Language Select"
          placeholder={t("languageSelect.placeholder")}
          selectionMode="single"
          clearDisabled={true}
          onCalciteComboboxChange={(event) => {
            const lang = String(event.target.value);
            i18n.changeLanguage(lang);
            intl.setLocale(lang);
            if (onChange) {
              onChange(lang);
            }
          }}
        >
          {Object.keys(languageLabels).map((key: string) => {
            return (
              <CalciteComboboxItem
                key={key}
                value={key}
                textLabel={languageLabels[key as keyof typeof languageLabels]}
                selected={i18n.language.includes(key.split("-")[0])}
              />
            );
          })}
        </CalciteCombobox>
      </CalciteLabel>
    </I18nextProvider>
  );
};

export default TranslationSelectI18n;
