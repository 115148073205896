import React, { useEffect, useState } from "react";

import { sanitizeUrl } from "@braintree/sanitize-url";

import { useTranslation, I18nextProvider } from "react-i18next";
import instance from "../../utils/i18n";

import { ErrorProps, ErrorTypes } from "./Error.types";

import "@esri/calcite-components/dist/components/calcite-alert";
import { CalciteAlert } from "@esri/calcite-components-react";

const Error = ({ message, error }: ErrorProps) => {
  const [messageText, setMessageText] = useState<string | undefined>();

  const { t, i18n } = useTranslation("components", { i18n: instance });

  useEffect(() => {
    switch (message) {
      case ErrorTypes.LIST:
        setMessageText(t("error.unableToLoadLocations"));
        break;
      case ErrorTypes.LOCATION:
        setMessageText(t("error.locationError"));
        break;
      case ErrorTypes.MAP:
        setMessageText(t("error.unableToLoadMap"));
        break;
      case ErrorTypes.SEARCH:
        setMessageText(t("error.searchError"));
        break;
      case ErrorTypes.SEARCH_BLOCKED:
        setMessageText(t("error.searchBlocked"));
        break;
      case ErrorTypes.STATUS:
        setMessageText(t("error.statusError"));
        break;
      case ErrorTypes.UNKNOWN:
        setMessageText(t("error.unknownError"));
        break;
      case ErrorTypes.ZONES:
        setMessageText(t("error.zonesError"));
        break;
      default:
        setMessageText(undefined);
    }
  }, [t, message, error]); // error is needed here to make sure that visible gets re-set in case the same message gets passed after this has been dismissed

  const dismissError = () => {
    setMessageText(undefined);
  };

  return (
    <I18nextProvider i18n={i18n}>
      {/* NOTE doc says that active is deprecated in favor of open, but I was not able to get it to work without active */}
      {/* If we bind active to something like messageText, we get a choppy animation so instead just exclude the component */}
      {messageText && (
        <CalciteAlert
          id="error-alert"
          color="red"
          label="Error"
          placement="bottom"
          open={true}
          messageOverrides={{ close: t("error.dismiss") }}
          data-testid="alert"
          onCalciteAlertClose={dismissError}
        >
          <div slot="message">{messageText}</div>
          {(message === ErrorTypes.LIST || message === ErrorTypes.MAP) && (
            <a slot="link" href={sanitizeUrl(window.location.toString())}>
              {t("error.tryAgain")}
            </a>
          )}
        </CalciteAlert>
      )}
    </I18nextProvider>
  );
};

export default Error;
