import React from "react";

import { useTranslation, I18nextProvider } from "react-i18next";
import instance from "../../utils/i18n";

import { MessageProps } from "./Message.types";

import NumberIcon from "../shared/NumberIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionSquare, faSquare } from "@fortawesome/pro-solid-svg-icons";

import { StyledMessage, StyledSpan, StyledWarning, StyledWarningTitle } from "./Message.styled";

const Message = ({ activeZoneNumber, zoneNumber }: MessageProps) => {
  const { t, i18n } = useTranslation("components", { i18n: instance });

  let isActive = false;
  let nullZone = false;
  let unknownZone = false;
  let text = "";
  if (zoneNumber !== undefined) {
    if (Number.isNaN(zoneNumber)) {
      nullZone = true;
      text = t("message.nonEvacZone");
    } else if (activeZoneNumber !== undefined && activeZoneNumber.includes(zoneNumber)) {
      isActive = true;
    }
  } else {
    unknownZone = true;
    text = t("message.unknownZone");
  }

  return (
    <I18nextProvider i18n={i18n}>
      <StyledMessage
        as={isActive ? StyledWarning : StyledMessage}
        style={
          {
            "--message-color": unknownZone
              ? "var(--lib-secondary-gray)"
              : "var(--lib-primary-green)",
          } as React.CSSProperties
        }
      >
        {/* todo: translate */}
        <h2 className="visually-hidden">{t("message.location")}</h2>
        <StyledWarningTitle>{t("message.evacRequired")}</StyledWarningTitle>
        <div id="info-content">
          {unknownZone && (
            <FontAwesomeIcon id="question-icon" icon={faQuestionSquare}></FontAwesomeIcon>
          )}
          {nullZone && <FontAwesomeIcon id="square-icon" icon={faSquare}></FontAwesomeIcon>}
          {!nullZone && !unknownZone && (
            <NumberIcon
              isActive={isActive}
              value={zoneNumber && zoneNumber < 7 ? zoneNumber : 1}
            ></NumberIcon>
          )}
          {unknownZone || nullZone ? (
            <p>{text}</p>
          ) : (
            <p>
              {t(`message.${isActive ? "evacRequiredFull" : "evacNotRequiredFull"}`, {
                zoneNumber: zoneNumber && zoneNumber < 7 ? zoneNumber : 1,
              })}
            </p>
          )}
        </div>
      </StyledMessage>
    </I18nextProvider>
  );
};

export default Message;
