import Graphic from "@arcgis/core/Graphic";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";
import { TFunction } from "react-i18next";

const getTodaysFieldPairs = (serviceTypes: string[], timeWindows: number[]) => {
  return _getDayOfWeekFieldPairs(serviceTypes, _getTodaysDayOfWeek(), timeWindows);
};

const _getNYCTimeTodayInUTC = (timeValue: string, t: TFunction) => {
  // check that timeValue looks like: 04:30 PM or 9:00 AM
  const hoursRegEx = /(0?[1-9]|1[0-2]):([0-5]\d)\s?((?:A|P)\.?M\.?)/i;
  if (!timeValue?.match(hoursRegEx)) {
    console.warn(t("filter.unexpectedFormat", { timeValue: timeValue }));
    return;
  }
  let hour = parseInt(timeValue.split(":")[0]);
  const minsAndAMPM = timeValue.replace(`${hour}:`, "").split(" ");
  const mins = parseInt(minsAndAMPM[0]);
  const isAM = minsAndAMPM[1].toUpperCase() === "AM";
  const isPM = minsAndAMPM[1].toUpperCase() === "PM";
  if (!(isAM || isPM) || !hour || isNaN(hour) || (!mins && mins !== 0) || isNaN(mins)) {
    console.warn(
      t("filter.unableToParse", {
        timeValue: timeValue,
        hour: hour,
        mins: mins,
        isAM: isAM,
        isPM: isPM,
      })
    );
    return;
  }
  // convert to 24-hour time
  if (hour === 12) {
    hour = 0;
  }
  if (isPM) {
    hour = hour + 12;
  }
  if ((hour || hour === 0) && (mins || mins === 0)) {
    // get date in NYC so that the day of the week is correct (only matters when date is different in NYC than in current time zone)
    const now = utcToZonedTime(Date.now(), "America/New_York");
    // construct new date object using today's date (in NYC) and the hours/mins from the open hours
    // the time zone on this is in the browser time zone
    const openDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hour, mins);
    // convert the time zone on the open hours date to NYC time
    const utcTime = zonedTimeToUtc(openDate, "America/New_York");
    return utcTime;
  }
};

// Gets today's day of the week as 3-char string
const _getTodaysDayOfWeek = () => {
  const now = new Date(Date.now());
  return now.toLocaleString("en-US", { weekday: "short" }).toLocaleLowerCase();
};

/**
 * Gets operating hours field pairs for given service types and day of the week
 * This function expects that open hours fields on the service have these formats:
 *       {serviceType}_{3-letter day of week}_open{index}
 *       {serviceType}_{3-letter day of week}_close{index} where index is 1, 2, and 3
 * It also assumes that all open hours data is in the Eastern time zone
 */
const _getDayOfWeekFieldPairs = (
  serviceTypes: string[],
  weekday: string,
  timeWindows: number[]
) => {
  const getFieldPair = (serviceType: string, iter: number) => {
    return [`${serviceType}_${weekday}_open${iter}`, `${serviceType}_${weekday}_close${iter}`];
  };
  const result = serviceTypes.flatMap((serviceType, index) => {
    const items = [];
    for (let i = 1; i <= timeWindows[index]; i++) {
      items.push(getFieldPair(serviceType, i));
    }
    return items;
  });
  return result;
};

const _getStartEndTimePairs = (loc: Graphic, startFieldName: string, endFieldName: string) => {
  const startTime = loc.attributes[startFieldName];
  const endTime = loc.attributes[endFieldName];
  return { startTime, endTime };
};

/**
 * For the given weekday and start/end time field pairs, find the next open start time on that weekday
 * If checkingForToday is true, will account for current time of day when finding the next open start time
 * If checkingForToday is false, will take the first opening time for the given day
 */
const _findNextOpenTimeOnWeekday = (
  loc: Graphic,
  applicableFieldPairs: string[][],
  weekday: string,
  checkingForToday: boolean,
  t: TFunction
) => {
  const now = new Date(Date.now());
  const startDates = applicableFieldPairs
    .map((fieldPair) => {
      const { startTime } = _getStartEndTimePairs(loc, fieldPair[0], fieldPair[1]);
      if (!startTime) return undefined;
      return _getNYCTimeTodayInUTC(startTime, t);
    })
    .filter((x) => !!x) as Date[];
  // if checking for today, filter out any start dates that already happened
  const futureStartDates = checkingForToday
    ? startDates.filter((start) => {
        return now < start;
      })
    : startDates;
  if (futureStartDates.length === 0) return;
  const sortedFutureStartDates = futureStartDates.sort((start1, start2) => {
    return start1.getTime() - start2.getTime();
  });
  const nextOpenTimeString = sortedFutureStartDates[0].toLocaleString("en-US", {
    timeZone: "America/New_York",
    timeStyle: "short",
  });
  return `${nextOpenTimeString} ${weekday}`;
};

/**
 * This function expects that open hours fields on the service have these formats:
 *       {serviceType}_{3-letter day of week}_open{index}
 *       {serviceType}_{3-letter day of week}_close{index} where index is 1, 2, and 3
 * It also assumes that all open hours data is in the Eastern time zone
 */
const updateOpenNow = (
  locations: Graphic[],
  serviceTypes: string[],
  timeWindows: number[],
  t: TFunction
) => {
  const now = new Date(Date.now());

  /* Setup for finding next open time */
  // get ordered array of days of week, starting today
  const daysOfTheWeekLoacle = t("details.dayNamesShort", { returnObjects: true });
  const daysOfTheWeek_DataKeys = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  const todayIndex = now.getDay();
  const todayWeekday_dataKey = daysOfTheWeek_DataKeys[todayIndex];
  const todayWeekdayLoacle = daysOfTheWeekLoacle[todayIndex];
  const reorderedWeekdays =
    todayIndex !== 0
      ? daysOfTheWeek_DataKeys.slice(todayIndex).concat(daysOfTheWeek_DataKeys.slice(0, todayIndex))
      : daysOfTheWeek_DataKeys;
  reorderedWeekdays.push(todayWeekday_dataKey);

  const reorderedWeekdaysLocale =
    todayIndex !== 0
      ? daysOfTheWeekLoacle.slice(todayIndex).concat(daysOfTheWeekLoacle.slice(0, todayIndex))
      : daysOfTheWeekLoacle;
  daysOfTheWeekLoacle.push(todayWeekdayLoacle);
  // add today back to the end, in case next opening is next week, earlier in the day than now
  // for example it's 1 pm Friday now and only open time is 9-11 am on Friday
  const todaysApplicableFieldPairs = getTodaysFieldPairs(serviceTypes, timeWindows);

  locations.forEach((loc) => {
    /* Update isOpenNow and nextCloseTime */
    const nextCloseTimes = todaysApplicableFieldPairs.map((fieldPair) => {
      const { startTime, endTime } = _getStartEndTimePairs(loc, fieldPair[0], fieldPair[1]);
      if (!startTime || !endTime) return undefined;

      const start = _getNYCTimeTodayInUTC(startTime, t);
      const end = _getNYCTimeTodayInUTC(endTime, t);
      const isOpen = start && end && now > start && now < end;
      const nextCloseTimeString = end?.toLocaleString("en-US", {
        timeZone: "America/New_York",
        timeStyle: "short",
      });
      return isOpen ? nextCloseTimeString : undefined;
    });
    const nextCloseTime = nextCloseTimes.filter((x) => !!x);
    // no values = not currently open; one value = currently open and value is next close time; >1 value unexpected
    if (nextCloseTime.length > 1) {
      console.warn(t("filter.multipleNextCloseTimes", { loc: loc }));
    }
    const isOpenNow = nextCloseTime.length > 0;

    /* Update nextOpenTime, if applicable */
    let nextOpenTime;
    if (!isOpenNow) {
      if (reorderedWeekdays.length !== 8)
        console.warn(t("filter.unexpectedNumWeekdays", { reorderedWeekdays: reorderedWeekdays }));
      // get first start time of every day of the week and filter any days without start times
      const firstStartTimes = reorderedWeekdays
        .map((weekday, index) => {
          const applicableFieldPairs = _getDayOfWeekFieldPairs(serviceTypes, weekday, timeWindows); // not the most efficient, but the least complicated
          const nextOpenTimeString = _findNextOpenTimeOnWeekday(
            loc,
            applicableFieldPairs,
            reorderedWeekdaysLocale[index],
            index === 0, // first time, check if it will be open later today
            t
          );
          return nextOpenTimeString;
        })
        .filter((x) => !!x) as string[];
      // set to first start time
      nextOpenTime = firstStartTimes.length > 0 ? firstStartTimes[0] : undefined;
    }

    if (isOpenNow) {
      loc.attributes["isOpenNow"] = true;
      loc.attributes["nextCloseTime"] = nextCloseTime[0];
    } else if (nextOpenTime) {
      loc.attributes["isOpenNow"] = false;
      loc.attributes["nextOpenTime"] = nextOpenTime;
    }
  });
};

export { updateOpenNow, getTodaysFieldPairs };
