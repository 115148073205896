import styled from "styled-components";
import { BooleanFilterBannerStyles } from "./BooleanFilterBanner.types";
import { Breakpoints } from "../AppTheme";

/* Containers */

export const StyledBooleanFilterBannerWrapper = styled.div`
  min-width: var(--lib-min-card-width);
  width: inherit;
  box-sizing: border-box;
  padding: var(--lib-default-spacing);

  //See StyledList
  margin: 2px 2px 3px 3px;
  border: 2px solid #fff;

  display: grid;
  grid-template-columns: 1fr auto;
  gap: var(--lib-default-spacing);
  align-items: start;

  background-color: var(--lib-foreground);

  //holds rows
  & #bfb-content {
    display: grid;
    grid-auto-flow: row;
    gap: var(--lib-default-spacing);
  }

  //button
  & > :last-child() {
    height: min-content;
  }

  @media ${Breakpoints.mobile} {
    display: ${({ isMapView }: BooleanFilterBannerStyles) => (isMapView ? "none" : "grid")};
  }
`;

export const StyledDefinitionRow = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--lib-default-spacing);
  align-items: center;

  width: 100%;
  box-sizing: border-box;

  font-size: var(--lib-text-size-0);

  //icon
  & > :first-child {
    color: var(--lib-primary-blue);
  }
`;
