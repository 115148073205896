// Framework and third-party non-ui
import React from "react";
import { StyledModalGrid, StyledModalTitle, StyledCalciteModal } from "./PhoneModal-styled";
import { PhoneModalProps } from "./PhoneModal.types";

import { I18nextProvider, useTranslation } from "react-i18next";
import instance from "../../utils/i18n";

const PhoneModal = ({ children, onClose, updateGoogleTranslate }: PhoneModalProps) => {
  const { t, i18n } = useTranslation("components", { i18n: instance });

  if (updateGoogleTranslate) {
    updateGoogleTranslate();
  }
  return (
    <I18nextProvider i18n={i18n}>
      <StyledCalciteModal id="phone-modal" open width="s" onCalciteModalClose={onClose}>
        <StyledModalTitle slot={"header"}>{t("phoneModal.emergencyHotlines")}</StyledModalTitle>
        <StyledModalGrid slot={"content"}>{children}</StyledModalGrid>
      </StyledCalciteModal>
    </I18nextProvider>
  );
};

export default PhoneModal;
