import React from "react";
import styled from "styled-components";
import { ArrayRowProps } from "../HeaderBar.types";

import NumberIcon from "../../shared/NumberIcon";

import { v4 as uuidv4 } from "uuid";

const StyledArrayRow = styled.div`
  //box-model
  width: 100%;
  height: min-content;
  box-sizing: border-box;
  padding: var(--lib-default-spacing);

  //layout
  display: grid;
  grid-auto-flow: column;
  gap: var(--lib-default-spacing);
  justify-content: center;

  background-color: var(--lib-foreground);
`;

const ArrayRow = ({ totalZones, activeZones }: ArrayRowProps) => {
  return (
    <StyledArrayRow>
      {totalZones &&
        0 <= totalZones &&
        totalZones < 10 &&
        [...Array(totalZones)].map((value: undefined, index: number) => {
          const isActiveZone = activeZones?.includes(index + 1);
          return (
            <NumberIcon
              value={index + 1}
              key={uuidv4()}
              isActive={isActiveZone !== undefined ? isActiveZone : false}
            ></NumberIcon>
          );
        })}
    </StyledArrayRow>
  );
};

export default ArrayRow;
