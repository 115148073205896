import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as intl from "@arcgis/core/intl.js";

import { StyledLanding } from "./Landing-styled";
import { LandingPageTypes } from "./Landing.types";
import { LinkInfo, EmergencyHotline } from "../../contexts/AppConfig.types";

import { useAppContext } from "../../contexts/AppContext";
import {
  FooterBar,
  HeaderBar,
  TextCard,
  FinderSearch,
  OnSearchCompleteProps,
  ErrorTypes,
  Error as ErrorComponent,
  ImageCard,
  PhoneModal,
  PhoneButton,
  ModalWrapper,
} from "nyc-component-lib";

import "@esri/calcite-components/dist/components/calcite-button";
import { CalciteButton } from "@esri/calcite-components-react";

const LandingPage = ({
  appTitle,
  sourceInfos,
  eventState,
  evacZones,
  alternateAppTitle,
  alternateBannerText,
  alternateDescriptionText,
  deactivated,
  emergency,
}: LandingPageTypes) => {
  const {
    mapView,
    searchTerm,
    updateSearchTerm,
    updateFocalPoint,
    appConfig,
    updateMapHighlight,
    updateLayoutDir,
  } = useAppContext();

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [errorMessage, setErrorMessage] = useState<ErrorTypes | undefined>();
  const [error, setError] = useState<unknown | undefined>();
  const [imageLinks, setImageLinks] = useState<LinkInfo[]>([]);
  const [importantLinks, setImportantLinks] = useState<LinkInfo[]>([]);
  const [emergencyHotlines, setEmergencyHotlines] = useState<EmergencyHotline[] | undefined>();
  const [bannerText, setBannerText] = useState<string>("");
  const [aboutCardText, setAboutCardText] = useState<string>("");
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  // trigger google translate re-translation if language is not set to English
  // called by this component on first render, and other components when they get new data that must be translated
  const updateGoogleTranslate = useCallback(() => {
    const lang = document.documentElement.lang;
    if (lang && lang !== "en") {
      document.querySelector(".goog-te-combo")?.dispatchEvent(new Event("change"));
    }
  }, []);

  useEffect(() => {
    if (t && appConfig?.appId && appConfig?.landing?.importantLinks) {
      const linkStringsArray = t(`app.landing.importantLinks.${appConfig.appId}`, {
        returnObjects: true,
      });
      const links = appConfig?.landing?.importantLinks.map((item, idx) => {
        return { ...item, ...linkStringsArray[idx] };
      });
      setImageLinks(links.filter((link) => link.backgroundLarge || link.backgroundSmall));
      setImportantLinks(links.filter((link) => !link.backgroundLarge && !link.backgroundSmall));
    }

    if (t && appConfig?.appId && appConfig?.landing?.emergencyHotlines) {
      const emergencyHotlinesAry = t(`app.landing.emergencyHotlines.${appConfig.appId}`, {
        returnObjects: true,
      });
      setEmergencyHotlines(
        appConfig?.landing?.emergencyHotlines.map((item, idx) => {
          return {
            ...item,
            ...emergencyHotlinesAry[idx],
          };
        })
      );
    }

    if (t && appConfig?.appId) {
      const bannerTextObj = t(`app.landing.bannerText.${appConfig.appId}`, {
        returnObjects: true,
      });

      // first check if there was an error when getting the event state
      if (eventState === "error") {
        setError(new Error(t("landing.unknownError")));
        setErrorMessage(ErrorTypes.STATUS);
      }

      for (const [k, v] of Object.entries(bannerTextObj)) {
        if (k === eventState) {
          setBannerText(alternateBannerText ? alternateBannerText : v);
        }
      }
      //#region === ABOUT CARD CONFIGURATION ===

      const aboutCardTextObj = t(`app.landing.aboutCardText.${appConfig.appId}`, {
        returnObjects: true,
        INFO_NUMBER: `<a href='tel:${appConfig.phone.info}'>${appConfig.phone.info}</a>`,
        TTY_NUMBER: `<a href='tel:2${appConfig.phone.tty}'>${appConfig.phone.tty}</a>`,
        VRS_NUMBER: `<a href='tel:${appConfig.phone.vrs}'>${appConfig.phone.vrs}</a>`,
      });
      for (const [k, v] of Object.entries(aboutCardTextObj)) {
        if (k === eventState) {
          setAboutCardText(alternateDescriptionText ? alternateDescriptionText : v);
        }
      }
    }
  }, [appConfig, t, eventState, alternateBannerText, alternateDescriptionText]);

  //#endregion === ABOUT CARD CONFIGURATION ===

  const onError = (message?: ErrorTypes, error?: unknown) => {
    if (error) console.error(t("landing.error", { error: error }));
    setError(error || new Error(t("landing.unknownError")));
    setErrorMessage(message || ErrorTypes.UNKNOWN);
  };

  const onSearchClear = () => {
    updateSearchTerm("");
    updateFocalPoint({});
    updateMapHighlight();
  };

  const onSearchComplete = ({ searchTerm, graphic, position }: OnSearchCompleteProps) => {
    updateSearchTerm(searchTerm);
    updateFocalPoint({
      graphic,
      position,
      isHidden: false,
    });
    navigate("/locations");
  };

  const onSearchBlocked = () => {
    onError(ErrorTypes.SEARCH_BLOCKED);
  };

  const onSearchError = (error: unknown) => {
    onError(ErrorTypes.SEARCH, error);
  };

  const onGoToView = (showMap: boolean) => {
    updateFocalPoint({});
    navigate(showMap ? "/locations?mView=map" : "/locations");
  };

  const handleLocaleChange = (locale: string) => {
    intl.setLocale(locale);
    i18n.changeLanguage(locale);
    updateLayoutDir(locale);
  };

  //#region === IMAGE CARD CONFIGURATION ===

  const defaultMapCardImage =
    appConfig?.landing.mapCardBackgroundLarge !== undefined
      ? `${process.env.PUBLIC_URL}/${appConfig?.landing.mapCardBackgroundLarge}`
      : `${process.env.PUBLIC_URL}/img/NYCMapImageLargeScreen.png`;

  const smallMapCardImage =
    appConfig?.landing.mapCardBackgroundSmall !== undefined
      ? `${process.env.PUBLIC_URL}/${appConfig?.landing.mapCardBackgroundSmall}`
      : `${process.env.PUBLIC_URL}/img/NYCMapImageSmallScreen.png`;
  //#endregion === IMAGE CARD CONFIGURATION ===

  //#region === HEADER CONFIGURATION ===

  /**
   * Hurricane
   */
  if (bannerText.includes("{{zones}}")) {
    let zones = "";
    if (evacZones?.length) {
      evacZones.forEach((zone, idx) => {
        if (idx === 0) {
          zones += zone;
        } else if (idx === evacZones.length - 1) {
          zones += t("general.seriesseparatorLastValue", { value: zone });
        } else {
          zones += t("general.seriesseparator", { value: zone });
        }
      });
      setBannerText(bannerText.replace("{{zones}}", zones));
    }
  }

  const hurricaneShowArrayProps = {
    totalZones: 6,
    activeZones: evacZones,
  };

  /**
   * Hurricane & Cooling Centers
   */
  const getBannerProps = () => {
    if (appConfig?.appId === "hurricane") {
      return {
        isActive: evacZones?.length ? true : false,
        message: bannerText,
      };
    }
    if (appConfig?.appId === "coolingcenters") {
      let message: string;
      if (deactivated) {
        message = t(`header.deactivated.coolingcenters`);
      } else {
        message = emergency
          ? t(`app.landing.bannerText.coolingcenters.active`)
          : t(`app.landing.bannerText.coolingcenters.default`);
      }
      return { isActive: deactivated || !emergency ? false : true, message: message };
    }
    return undefined;
  };

  //#endregion === HEADER CONFIGURATION ===
  let cc_about_text = "";
  if (appConfig?.appId === "coolingcenters") {
    if (deactivated) {
      cc_about_text = t("app.landing.aboutCardTextInactive.coolingcenters");
    } else {
      if (emergency) {
        cc_about_text = t("app.landing.aboutCardText.coolingcenters.active", {
          INFO_NUMBER: `<a href='tel:${appConfig.phone.info}'>${appConfig.phone.info}</a>`,
          TTY_NUMBER: `<a href='tel:2${appConfig.phone.tty}'>${appConfig.phone.tty}</a>`,
          VRS_NUMBER: `<a href='tel:${appConfig.phone.vrs}'>${appConfig.phone.vrs}</a>`,
        });
      } else {
        cc_about_text = t("app.landing.aboutCardText.coolingcenters.default", {
          INFO_NUMBER: `<a href='tel:${appConfig.phone.info}'>${appConfig.phone.info}</a>`,
          TTY_NUMBER: `<a href='tel:2${appConfig.phone.tty}'>${appConfig.phone.tty}</a>`,
          VRS_NUMBER: `<a href='tel:${appConfig.phone.vrs}'>${appConfig.phone.vrs}</a>`,
        });
      }
    }
  }

  const renderImageLinks = () => (
    <>
      {imageLinks.length > 0 && (
        <div id="image-links">
          {imageLinks.map((linkInfo) => {
            return (
              <a
                href={linkInfo.url}
                target="_blank"
                rel="noreferrer"
                aria-label={linkInfo.ariaLabel}
              >
                <ImageCard
                  imgSrcDefault={`${process.env.PUBLIC_URL}/${linkInfo.backgroundLarge}`}
                  // imgSrcNarrow={`${process.env.PUBLIC_URL}/${linkInfo.backgroundSmall}`}
                >
                  <div className="link-card" />
                </ImageCard>
              </a>
            );
          })}
        </div>
      )}
    </>
  );

  return (
    <StyledLanding
      backgroundImgSrc={`${process.env.PUBLIC_URL}/${appConfig?.landing.backgroundImage}`}
    >
      {appConfig?.landing.includePhoneModal === true && showModal === true && (
        <ModalWrapper>
          <PhoneModal onClose={toggleModal} updateGoogleTranslate={updateGoogleTranslate}>
            {emergencyHotlines?.map((hotline) => {
              return (
                <PhoneButton
                  key={`emergHotline_${hotline.phoneNumber}`}
                  message={hotline.text}
                  phoneNum={hotline.phoneNumber}
                ></PhoneButton>
              );
            })}
          </PhoneModal>
        </ModalWrapper>
      )}
      <div id="header-container">
        <HeaderBar
          title={alternateAppTitle ? alternateAppTitle : appTitle}
          headerType="landing"
          deactivated={deactivated}
          showBanner={getBannerProps()}
          showArray={`${appConfig?.appId}` === "hurricane" ? hurricaneShowArrayProps : undefined}
          logoFirst={appConfig?.appInfo.logoFirst}
          onHomeClick={() => {
            navigate("/");
          }}
        >
          {sourceInfos && (
            <FinderSearch
              mapView={mapView}
              sourceInfos={sourceInfos}
              searchTerm={searchTerm}
              isLanding={true}
              onSearchClear={onSearchClear}
              onSearchComplete={onSearchComplete}
              onSearchBlocked={onSearchBlocked}
              onSearchError={onSearchError}
            />
          )}
        </HeaderBar>
      </div>

      <div id="page-content">
        {appConfig?.landing.includePhoneModal && (
          <PhoneButton
            message={t("app.landing.emergencyHotlines.buttonText")}
            onClick={toggleModal}
          ></PhoneButton>
        )}
        {appConfig && (
          <TextCard
            opacity={appConfig?.landing.textCardOpacity || 1}
            title={t(`app.landing.aboutCardTitle.${appConfig.appId}`)}
          >
            <p
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{
                __html: deactivated
                  ? t(`app.landing.aboutCardTextInactive.${appConfig.appId}`)
                  : appConfig.appId === "coolingcenters"
                  ? cc_about_text
                  : aboutCardText,
              }}
            ></p>
          </TextCard>
        )}
        {!emergency && renderImageLinks()}
        {deactivated === false && (
          <div id="goto-buttons">
            <ImageCard imgSrcDefault={defaultMapCardImage} imgSrcNarrow={smallMapCardImage}>
              <div id="map-card">
                <CalciteButton
                  id="goto-map-btn"
                  onClick={() => {
                    onGoToView(true);
                  }}
                  iconStart="map-pin"
                  color="blue"
                  appearance="solid"
                  round
                >
                  {t("landing.viewMap")}
                </CalciteButton>
              </div>
            </ImageCard>
            <span id="goto-list-card-wrapper">
              <ImageCard imgSrcDefault={`${process.env.PUBLIC_URL}/img/NYCListImage.png`}>
                <div id="list-card">
                  <CalciteButton
                    id="goto-list-btn"
                    onClick={() => {
                      onGoToView(false);
                    }}
                    iconStart="list"
                    color="blue"
                    appearance="solid"
                    round
                  >
                    {t("landing.viewList")}
                  </CalciteButton>
                </div>
              </ImageCard>
            </span>
          </div>
        )}
        {emergency && renderImageLinks()}
        {importantLinks.length > 0 && (
          <TextCard
            opacity={appConfig?.landing.textCardOpacity || 1}
            title={t("landing.importantLinks")}
          >
            <ul>
              {importantLinks.map((linkInfo) => {
                return (
                  <li key={linkInfo.url}>
                    {linkInfo.desc && <span>{linkInfo.desc}</span>}
                    <a
                      href={linkInfo.url}
                      target="_blank"
                      rel="noreferrer"
                      aria-label={linkInfo.ariaLabel}
                    >
                      {linkInfo.label}
                    </a>
                  </li>
                );
              })}
            </ul>
          </TextCard>
        )}
      </div>
      <FooterBar
        footerType="landing"
        faqUrl={appConfig?.appInfo.faqURL}
        onLocaleChange={appConfig?.useGoogleTranslate ? undefined : handleLocaleChange}
      ></FooterBar>
      <ErrorComponent message={errorMessage} error={error} />
    </StyledLanding>
  );
};

export default LandingPage;
