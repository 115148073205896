// Framework and third-party non-ui
import React, { createContext, useContext, useState, useEffect } from "react";
import { MapController } from "nyc-component-lib";
import { AppContext as AppContextType, UpdateFocalPointArgs } from "./AppContext.types";
import MapView from "@arcgis/core/views/MapView";

import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import esriRequest from "@arcgis/core/request";

import { _updateFocalPoint } from "./AppController";
import { AppConfig } from "./AppConfig.types";

export const AppContext = createContext<AppContextType | null>(null);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AppContextProvider = ({ children, isAppActive }: any) => {
  const [appConfig, setAppConfig] = useState<AppConfig | undefined>();
  const [mapView, setMapView] = useState<MapView>();
  const [locationsFeatureLayer, setLocationsFeatureLayer] = useState<FeatureLayer | undefined>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [focalPoint, setFocalPoint] = useState<Graphic | undefined>();
  const [filterWhere, setFilterWhere] = useState<string>("");
  const [evacZones, setEvacZones] = useState<number[]>([]);
  const [eventStatus, setEventStatus] = useState<string>("");
  const [alternateAppTitle, setAlternateAppTitle] = useState<string>("");
  const [alternateBannerText, setAlternateBannerText] = useState<string>("");
  const [alternateDescriptionText, setAlternateDescriptionText] = useState<string>("");
  const [layoutDir, setLayoutDir] = useState<string>("");
  const [isEmergency, setIsEmergency] = useState<boolean>(false);

  const updateMapView = async (mapView: MapView) => {
    setMapView(mapView);
  };

  const updateLocationsFeatureLayer = (layer: FeatureLayer) => {
    setLocationsFeatureLayer(layer);
  };

  const updateMapHighlight = (newHighlight?: __esri.Handle) => {
    // This fixes bug 35678
    // and bug 36330
    // Previously used useState here but
    // seems to be an async issue with losing handles.
    if (window.mapHighlight) window.mapHighlight.remove();
    window.mapHighlight = newHighlight;
  };

  const updateFilterWhere = async (where: string) => {
    setFilterWhere(where);
  };

  const updateFocalPoint = (args: UpdateFocalPointArgs) => {
    _updateFocalPoint(args, setFocalPoint);
  };

  const updateSearchTerm = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };

  const rtlLangs: string[] = ["ar", "ur", "yi"]; // Arabic | Urdu | Yiddish

  const updateLayoutDir = (lang: string) => {
    setLayoutDir(rtlLangs.includes(lang) ? "rtl" : "ltr");
  };

  const getEmergencyStatus = async () => {
    try {
      const deactivate_test = window.activeMapConfig?.deactivate_test;
      if (!deactivate_test) {
        return true;
      }
      const { request, field_name, emergency_value } = deactivate_test;
      const results = await esriRequest(request.url, request.options);
      const recent_value = results.data.features[0].attributes[field_name];

      // return value is NOT equal to emergency state
      console.log(`Emergency value = ${emergency_value} the current value is ${recent_value}`);
      setIsEmergency(emergency_value === recent_value);
    } catch (error) {
      console.error("Error performing emergency test, setting app to default status.", error);
      setIsEmergency(false);
    }
  };

  const getEvacZones = async () => {
    try {
      const evacuate_test = window.activeMapConfig?.evacuate_test;
      if (!evacuate_test) {
        setEventStatus("default");
        setEvacZones([]);
        return true;
      }
      const {
        request,
        field_names,
        evacuate_value,
        status_field,
        alt_app_title_field,
        alt_banner_text_field,
        alt_description_text_field,
      } = evacuate_test;

      esriRequest(request.url, request.options)
        .then((results) => {
          const evacuate_zones: number[] = [];

          field_names.forEach((field_name: string) => {
            const recent_value = results.data.features[0].attributes[field_name];
            if (evacuate_value === recent_value) {
              evacuate_zones.push(Number(field_name.split("_").at(-1)));
            }
          });

          const eventStatus = results.data.features[0].attributes[status_field];

          setEventStatus(eventStatus === "none" ? "default" : eventStatus);
          setEvacZones(evacuate_zones);
          setAlternateAppTitle(results.data.features[0].attributes[alt_app_title_field]);
          setAlternateBannerText(results.data.features[0].attributes[alt_banner_text_field]);
          setAlternateDescriptionText(
            results.data.features[0].attributes[alt_description_text_field]
          );
        })
        .catch((error) => {
          setEventStatus("error");
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getEvacZones();
    getEmergencyStatus();
  }, []);

  useEffect(() => {
    if (mapView) {
      mapView.on("click", () => {
        updateMapHighlight();
      });
    }
    // setting exhaustive-deps causes loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapView]);

  useEffect(() => {
    if (mapView) {
      mapView.popup.close();
      MapController.goToLocationAndShowPin({
        mapView,
        layerUrl: `${window.activeMapConfig?.loc_layer_url}`,
        pinPoint: focalPoint,
        pinImageUrl: `${process.env.PUBLIC_URL}/mappin.svg`,
        updateMapHighlight,
        objectIdFieldname: appConfig?.fields.locObjectIDFieldname || "FID",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapView, focalPoint]);

  useEffect(() => {
    if (mapView) {
      MapController.updateFilterOnMapView(
        mapView,
        `${window.activeMapConfig?.loc_layer_url}`,
        filterWhere
      );
    }
  }, [filterWhere, mapView]);

  return (
    <AppContext.Provider
      value={{
        appConfig,
        updateAppConfig: setAppConfig,
        mapView,
        updateMapView,
        locationsFeatureLayer,
        updateLocationsFeatureLayer,
        searchTerm,
        updateSearchTerm,
        focalPoint,
        updateFocalPoint,
        filterWhere: filterWhere,
        updateFilterWhere,
        updateMapHighlight,
        isActive: isAppActive,
        isEmergency,
        evacZones,
        eventStatus,
        alternateAppTitle,
        alternateBannerText,
        alternateDescriptionText,
        layoutDir,
        updateLayoutDir,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const appContext = useContext(AppContext);
  if (!appContext) {
    // the below text is for developers not for users. It does not need to be translated
    throw new Error("Cannot use 'useAppContext' outside of a AppContextProvider");
  }
  return appContext;
};
