import React from "react";
import styled from "styled-components";
import { BannerRowProps, StyledBannerProps } from "../HeaderBar.types";

const StyledBannerRow = styled.div`
  //box-model
  width: 100%;
  box-sizing: border-box;
  padding: var(--lib-default-spacing);

  display: flex;
  justify-content: center;

  text-align: center;
  color: var(--lib-foreground);
  font-size: var(--lib-text-size-1);

  background-color: var(
    --lib-primary-${({ isActive }: StyledBannerProps) => (isActive ? "red" : "green")}
  );

  span {
    min-width: var(--lib-min-card-width);
    max-width: var(--lib-max-card-width);
  }
`;

const BannerRow = ({ isActive, message }: BannerRowProps) => {
  return (
    <StyledBannerRow isActive={isActive}>
      <span>{message}</span>
    </StyledBannerRow>
  );
};

export default BannerRow;
