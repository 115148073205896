export interface ErrorProps {
  message?: ErrorTypes;
  error?: unknown;
}

export interface StyledErrorProps {
  open: boolean;
}

export enum ErrorTypes {
  LIST = "LIST_ERROR",
  LOCATION = "LOCATION_ERROR",
  MAP = "MAP_ERROR",
  SEARCH = "SEARCH_ERROR",
  SEARCH_BLOCKED = "SEARCH_BLOCKED_ERROR",
  STATUS = "STATUS_ERROR",
  UNKNOWN = "UNKNOWN_ERROR",
  ZONES = "ZONES_ERROR",
}
