// Framework and third-party non-ui
import React from "react";

import { useTranslation, I18nextProvider } from "react-i18next";
import instance from "../../utils/i18n";

import {
  StyledHeader,
  StyledLandingHeader,
  StyledLocationsHeader,
  StyledBrandContainer,
  StyledSearchContainer,
  StyledSearchContainerTop,
} from "./HeaderBar-styled";
import { HeaderProps } from "./HeaderBar.types";

import BannerRow from "./HeaderRows/BannerRow";
import ArrayRow from "./HeaderRows/ArrayRow";
import HeaderLogo from "./logo.svg";
import HeaderLogoWhite from "./logo_white.svg";

const HeaderBar = ({
  title,
  headerType,
  logoFirst,
  children,
  deactivated,
  showBanner,
  showArray,
  onHomeClick,
}: HeaderProps) => {
  const { t, i18n } = useTranslation("components", { i18n: instance });
  const isLanding = headerType === "landing";
  return (
    <I18nextProvider i18n={i18n}>
      <StyledHeader as={isLanding ? StyledLandingHeader : StyledLocationsHeader}>
        {logoFirst && (
          <StyledBrandContainer>
            <button onClick={onHomeClick} aria-label={t("header.homeLabel1", { title: title })}>
              <img
                src={isLanding ? HeaderLogo : HeaderLogoWhite}
                alt={t("header.homeLabel1", { title: title })}
                id="logo"
              />
            </button>
            <h1>{title}</h1>
          </StyledBrandContainer>
        )}
        {!logoFirst && (
          <StyledBrandContainer>
            <h1>{title}</h1>
            <button onClick={onHomeClick} aria-label={t("header.homeLabel2", { title: title })}>
              <img
                src={isLanding ? HeaderLogo : HeaderLogoWhite}
                alt={t("header.homeLabel2", { title: title })}
                id="logo"
              />
            </button>
          </StyledBrandContainer>
        )}
        {isLanding && (showBanner || showArray) && (
          <div>
            {/* todo: update translation */}
            <h2 className="visually-hidden">{`${title} ${t("header.status")}`}</h2>
            {showBanner && <BannerRow {...showBanner}></BannerRow>}
            {showArray && <ArrayRow {...showArray}></ArrayRow>}
          </div>
        )}
        {deactivated === false && (
          <StyledSearchContainer>
            <StyledSearchContainerTop>
              <h2>{t("header.findALocation")}</h2>
            </StyledSearchContainerTop>
            {children}
          </StyledSearchContainer>
        )}
      </StyledHeader>
      {!isLanding && showBanner && <BannerRow {...showBanner}></BannerRow>}
    </I18nextProvider>
  );
};

export default HeaderBar;
