import React, { useRef } from "react";

import { useTranslation, I18nextProvider } from "react-i18next";
import instance from "../../../utils/i18n";

import { v4 as uuidv4 } from "uuid";

import {
  StyledCategoryFilter,
  StyledPopoverContainer,
  StyledPopoverButtons,
  StyledCFRow,
} from "./CategoryFilter-styled";
import { CategoryFilterProps } from "../Filter.types";

import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-popover";
import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-checkbox";
import "@esri/calcite-components/dist/components/calcite-radio-button";
import "@esri/calcite-components/dist/components/calcite-radio-button-group";
import {
  CalciteButton,
  CalcitePopover,
  CalciteLabel,
  CalciteCheckbox,
  CalciteRadioButton,
  CalciteRadioButtonGroup,
  CalciteLink,
} from "@esri/calcite-components-react";

const CategoryFilter = ({
  allOptions,
  onToggleOption,
  onToggleAll,
  isFullscreen,
  type,
  anyLabel,
  selectedLabel,
}: CategoryFilterProps) => {
  const popoverRef = useRef<HTMLCalcitePopoverElement>(null);

  const { t, i18n } = useTranslation("components", { i18n: instance });

  const onClose = () => {
    if (popoverRef?.current) {
      popoverRef.current.open = !popoverRef.current.open;
    }
  };

  return (
    <I18nextProvider i18n={i18n}>
      {isFullscreen ? (
        <span>
          {allOptions?.length > 0 &&
            allOptions?.map((option) => (
              <StyledCFRow>
                <CalciteLabel key={uuidv4()} layout="inline" onClick={() => onToggleOption(option)}>
                  {/* "|| undefined" below is workaround for issue with Calcite React where passing a boolean converts the value to a string */}
                  {(type === "checkbox" ||
                    type === "checkbox-multi-field" ||
                    type === "notnull") && (
                    <CalciteCheckbox
                      value={option}
                      checked={option.checked || undefined}
                      onCalciteCheckboxChange={() => onToggleOption(option)}
                    />
                  )}
                  {type === "radio" && (
                    <CalciteRadioButton value={option} checked={option.checked || undefined} />
                  )}
                  {option.label}
                </CalciteLabel>
                {option.toolTip && (
                  <>
                    <div className="info-link-wrapper">
                      (
                      <CalciteLink
                        class="info-link"
                        tabIndex={0}
                        id={`tooltip-button-${option.label.replace(/ /g, "_")}`}
                        aria-label={t("categoryFilter.dataDisclaimer")}
                      >
                        ?
                      </CalciteLink>
                      )
                    </div>
                    <CalcitePopover
                      class="calcite-mode-dark"
                      label={t("categoryFilter.dataDisclaimer")}
                      referenceElement={`tooltip-button-${option.label.replace(/ /g, "_")}`}
                      scale="s"
                      closable
                    >
                      <span className="popover-content">{option.toolTip}</span>
                    </CalcitePopover>
                  </>
                )}
              </StyledCFRow>
            ))}
        </span>
      ) : (
        <StyledCategoryFilter>
          <CalciteButton
            id={`category-filter-button-${anyLabel.replace(/ /g, "_")}`}
            iconEnd="chevron-down"
            class={allOptions.filter((o) => o.checked).length > 0 ? "" : "lib-neutral-button"}
            appearance={allOptions.filter((o) => o.checked).length > 0 ? "solid" : "outline"}
            color={allOptions.filter((o) => o.checked).length > 0 ? "blue" : "neutral"}
            label={
              allOptions.filter((o) => o.checked).length > 0
                ? `${selectedLabel} (${allOptions.filter((o) => o.checked).length})`
                : anyLabel
            }
          >
            {allOptions.filter((o) => o.checked).length > 0
              ? `${selectedLabel} (${allOptions.filter((o) => o.checked).length})`
              : anyLabel}
          </CalciteButton>
          <CalcitePopover
            label={t("categoryFilter.selectTypes")}
            // using a ref for the referenceElement worked on first render, but once fullscreen mode was toggled the ref was not set on the next render
            // and caused the popover and button to get initialized improperly.  Hardcoded ids are less elegant but solve this problem.
            referenceElement={`category-filter-button-${anyLabel.replace(/ /g, "_")}`}
            auto-close
            id="category-filter-popover"
            ref={popoverRef}
          >
            <StyledPopoverContainer>
              {type === "radio" && allOptions?.length > 0 && (
                <CalciteRadioButtonGroup name={uuidv4()} layout="vertical">
                  {allOptions?.map((option) => (
                    <StyledCFRow>
                      <CalciteLabel
                        key={uuidv4()}
                        layout="inline"
                        className="label-nowrap"
                        onClick={() => onToggleOption(option)}
                      >
                        {/* "|| undefined" below is workaround for issue with Calcite React where passing a boolean converts the value to a string */}
                        <CalciteRadioButton value={option} checked={option.checked || undefined} />
                        {option.label}
                      </CalciteLabel>
                      {option.toolTip && (
                        <>
                          <div className="info-link-wrapper">
                            (
                            <CalciteLink
                              class="info-link"
                              id={`tooltip-button-${option.label.replace(/ /g, "_")}`}
                              tabIndex={0}
                              aria-label={t("categoryFilter.dataDisclaimer")}
                            >
                              ?
                            </CalciteLink>
                            )
                          </div>
                          <CalcitePopover
                            class="calcite-mode-dark"
                            label={t("categoryFilter.dataDisclaimer")}
                            referenceElement={`tooltip-button-${option.label.replace(/ /g, "_")}`}
                            scale="s"
                            closable
                          >
                            <span className="popover-content">{option.toolTip}</span>
                          </CalcitePopover>
                        </>
                      )}
                    </StyledCFRow>
                  ))}
                </CalciteRadioButtonGroup>
              )}

              {(type === "checkbox" || type === "checkbox-multi-field" || type === "notnull") &&
                allOptions?.length > 0 &&
                allOptions?.map((option) => (
                  <StyledCFRow>
                    <CalciteLabel
                      key={uuidv4()}
                      layout="inline"
                      onClick={() => onToggleOption(option)}
                    >
                      {/* "|| undefined" below is workaround for issue with Calcite React where passing a boolean converts the value to a string */}
                      <CalciteCheckbox
                        value={option}
                        checked={option.checked || undefined}
                        onCalciteCheckboxChange={() => onToggleOption(option)}
                      />
                      {option.label}
                    </CalciteLabel>
                    {option.toolTip && (
                      <>
                        <div className="info-link-wrapper">
                          (
                          <CalciteLink
                            class="info-link"
                            id={`tooltip-button-${option.label.replace(/ /g, "_")}`}
                            tabIndex={0}
                            aria-label={t("categoryFilter.dataDisclaimer")}
                          >
                            ?
                          </CalciteLink>
                          )
                        </div>
                        <CalcitePopover
                          class="calcite-mode-dark"
                          label={t("categoryFilter.dataDisclaimer")}
                          referenceElement={`tooltip-button-${option.label.replace(/ /g, "_")}`}
                          scale="s"
                          closable
                        >
                          <span className="popover-content">{option.toolTip}</span>
                        </CalcitePopover>
                      </>
                    )}
                  </StyledCFRow>
                ))}
              <StyledPopoverButtons>
                {type !== "radio" && (
                  <CalciteButton
                    class="lib-neutral-button"
                    color="neutral"
                    appearance="outline"
                    id="select-all-btn"
                    onClick={onToggleAll}
                  >
                    {allOptions.find((o) => !o.checked)
                      ? t("categoryFilter.selectAll")
                      : t("categoryFilter.unselectAll")}
                  </CalciteButton>
                )}
                {
                  <CalciteButton
                    id="close-category-filter-btn"
                    onClick={onClose}
                    color="blue"
                    appearance="solid"
                  >
                    {t("categoryFilter.close")}
                  </CalciteButton>
                }
              </StyledPopoverButtons>
            </StyledPopoverContainer>
          </CalcitePopover>
        </StyledCategoryFilter>
      )}
    </I18nextProvider>
  );
};

export default CategoryFilter;
